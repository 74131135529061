


























































































































































































import { LoadingStatusType, PackageStatus, SortType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import AuthService from "@/service/authService";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StorePackage = namespace("Package");

const auth = new AuthService();
@Component({})
export default class PackageTable extends Vue {
  @StorePackage.Action
  private DoGetPackageTable!: (input: any) => void;

  @StorePackage.Getter
  private getPackageTable!: Array<any> | null;

  @StorePackage.Getter
  private getCountItemTable!: number;

  @StorePackage.Getter
  private getPackageTableLoadingStatus!: LoadingStatusType | null;

  private textSearch = "";
  private packageStatus = PackageStatus.All;
  private PackageStatus = PackageStatus;
  private packageStatusList = [
    PackageStatus.All,
    PackageStatus.Draft,
    PackageStatus.Approved,
    PackageStatus.Canceled,
    PackageStatus.OnProcess,
  ];
  private packageStatusText = ["All", "Draft", "Approved", "Canceled", "On Process"];
  private paymentGatewayTypeText = [
    "None",
    "SCB",
    "Omise",
    "SCBQR",
    "Swap",
    "Tranfer",
    "Change to AFP",
    "Change to normal",
  ];
  private table: any = {
    header: [
      { text: "", value: "index" },
      { text: "Payment ID", value: "id", sortable: true, sortType: SortType.Order },
      { text: "Merchant ID", value: "merchantid", sortable: true, sortType: SortType.Order },
      { text: "Merchant Code", value: "merchantCode" },
      { text: "Merchant Name", value: "merchantName" },
      { text: "Package", value: "package" },
      { text: "CreateDate", value: "createDate" },
      { text: "ExpiredDate", value: "expiredDate" },
      { text: "GatewayType", value: "gatewayType" },
      { text: "Price (+VAT) ฿", value: "price" },
      { text: "Status", value: "status" },
      { text: "Receipt", value: "receipt" },
    ],
    detail: [],
  };
  private datefrom = "";
  private menufrom = false;
  private modalfrom = false;
  private menu2from = false;
  private dateto = "";
  private menuto = false;
  private modalto = false;
  private menu2to = false;
  private pageNo = 1;
  private pageSize = 10;

  private countItem = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];

  private createPermission = false;
  private sortPermission = false;
  SortType = SortType;
  selectedItem = "";
  selectedSortBy = "";

  @Watch("getPackageTableLoadingStatus", { immediate: true })
  getPackageTableLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.table.detail = [];
      if (this.getPackageTable && this.getPackageTable.length > 0) {
        this.getPackageTable.forEach((item: any) => {
          this.table.detail.push(NewObject(item));
        });
      }
      this.table.detail.sort((a: any, b: any) => a.order - b.order);
      this.countItem = this.getCountItemTable;

      if (this.countItem === 0) {
        this.totalPage = 1;
      } else {
        this.totalPage = Math.ceil(this.getCountItemTable / this.pageSize);
      }
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }

  @Watch("pageNo")
  pageNoChanged(newVal: number) {
    this.GetPackageTable(this.pageNo, this.pageSize);
  }

  @Watch("pageSize")
  pageSizeChanged(newVal: number) {
    this.GetPackageTable(1, this.pageSize);
  }
  created() {
    this.GetPackageTable(this.pageNo, this.pageSize);
  }
  public GetPackageTable(pageNo = this.pageNo, pageSize = this.pageSize) {
    const packageTableViewModel: any = {
      pageSize: pageSize,
      pageNo: pageNo,
      fromDate: this.datefrom,
      toDate: this.dateto,
      merchantCode: this.textSearch,
      sortItem: this.selectedItem,
      sortBy: this.selectedSortBy,
      peakPaymentConnectTableStatus: this.packageStatusList[this.packageStatus],
    };
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.DoGetPackageTable(packageTableViewModel);
  }
  public SearchPackageTable() {
    this.GetPackageTable(this.pageNo, this.pageSize);
  }
  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }
  public SortTable(column: string, order: string) {
    this.selectedItem = column;
    this.selectedSortBy = order;
    this.GetPackageTable(this.pageNo, this.pageSize);
  }
  public ClearSortTable() {
    this.selectedSortBy = "desc";
    this.selectedItem = "";
    this.GetPackageTable(this.pageNo, this.pageSize);
  }
  public SearchPackage() {
    this.GetPackageTable(1, 10);
    this.pageNo = 1;
    this.pageSize = 10;
  }
  public GetDateTimeFormat(_date: string) {
    let newDate = new Date(_date);
    newDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000);
    let fullYear = newDate.getFullYear().toString();
    let month = (newDate.getMonth() + 1).toString();
    let date = newDate.getDate().toString();
    return `${date.length == 2 ? date : "0" + date}/${month.length == 2 ? month : "0" + month}/${fullYear}`;
  }
  public ViewPackageDetail(id: string, merchantId: string) {
    const routeData = this.$router.resolve({
      name: "packageDetail",
      query: { id: id, merchantId: merchantId },
    });
    window.location.href = routeData.href;
  }
}
