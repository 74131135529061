






import { Component, Vue } from "vue-property-decorator";
import PackageTable from "./PackageTable.vue";

@Component({ components: { PackageTable } })
export default class Package extends Vue {}
